@import '../../assets/styles/variables';

.wrap {
  font-weight: 300;
  color: #090909;
  font-size: 20px;
}

.wrapSmall {
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: $grey;
}