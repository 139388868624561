@import '../../assets/styles/variables';

.ppl {
  background-color: $purple;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  box-shadow: 0 0 13px 0 $blue;
  transition: .3s ease-out;
}

.closeIco {
  position: absolute;
  top: 13px;
  right: 15px;
  width: 23px;
  height: 23px;
  cursor: pointer;
  z-index: 10;

  &:before, &:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 23px;
    height: 3px;
    background-color: #00FFF2;
    border-radius: 5px;
  }

  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.title {
  color: $blue;
}

.text {
  color: $grey
}

.btn {
  border-radius: 5px;
  font-size: 13px;
  line-height: 25px;
  text-transform: capitalize;
}

.btnDisabled {
  opacity: .7;
}