@import './../../assets/styles/media-mixin';

.wrap {
  background-color: $purple;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  z-index: 20;
  @include media(L) {
    padding: 15px 0;
  }
  &>div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    @include media(L) {
      justify-content: space-between;
    }
  }
}


.navMenu {}

.navLink {
  margin-right: 15px;
  font-size: 18px;
  color: #fff;
  &:hover {
    color: $blue;
    text-decoration: none;
  }
}

.btn {
  background-color: $purple1;
  border-radius: 25px;
  font-size: 18px;
  color: #fff;
  padding: 2px 15px;

  &:hover {
    background-color: $blue;
    color: $purple;
    cursor: pointer;
    text-decoration: none;
  }
}

.regBtn {
  position: absolute;
  right: 0;
}