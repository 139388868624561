@import '../../assets/styles/media-mixin';

.wrap {
  font-family: 'bold', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 35px;
  color: $purple1;
  font-size: 22px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  @include media(L) {
    font-size: 32px;
  }
}

.wrapSmall {
  font-size: 18px;
  line-height: 21px;
  color: $purple1;
  @include media(L) {
    font-size: 26px;
    line-height: 30px;
  }
}