@import '../../assets/styles/media-mixin';


.banner {
  margin: 5px 0 25px;
  img {
    max-width: 100%;
  }
}
.infoTitle {
  text-align: left;
  align-items: flex-start;
  font-size: 21px;
  font-family: "bold", sans-serif;
  margin-bottom: 10px;
  padding-bottom: 10px;
  background: $violet-gradient bottom no-repeat;
  background-size: 100% 3px;
  padding-right: 17px;
  @include media(M){
    padding-right: unset;
    margin-bottom: 20px;
    font-size: 28px;
    font-family: "regular", sans-serif;
    text-align: left;
    min-height: 80px;
  }
}
.info__button {
  position: relative;
  background-color: transparent;
  border: none;
  width: 100%;
}
.info__text {
  text-align: left;
}
.infoArticle {
  font-size: 18px;
}
.accordion {
  width: 100%;
}
.bottom_gradient {
  width: 100%;
  height: 65px;
  background: $dark-gradient;
}
.cases {
  font-family: "regular", sans-serif;
  background-color: $purple;
}
.case_title {
  color: $blue;
}
.cases__tabs {
  font-size: 20px;
  line-height: 27px;
  margin: 30px 0;
  color: $blue;
  align-self: flex-start;
}

.tab__title {
  display: flex;
  margin-right: 14px;
  margin-bottom: 10px;
  padding: 5px 0;
  @include media(M){
    margin-right: 30px;
  }

  &>div {
    font-size: 16px;
    line-height: 25px;
    color: $blue;
  }
}
.info_block {
  margin: 40px auto 0 auto;
}
.subTitle {
  font-family: "bold", sans-serif;
}
.item_list {
  margin-bottom: 20px;
  align-items: flex-start;
  display: flex;
  @include media(M){
    margin-bottom: 25px;
  }
}
.list_title {
  font-family: "bold", sans-serif;
}
.icon {
  display: none;
  min-width: 31px;
  @include media(M){
    display: block;
  }
}