@import '../../assets/styles/media-mixin';

.bThumb {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 245px;
  @include media(L) {
    height: 445px;
  }
  &:after {
    content: '';
    background: rgba(65, 68, 195, 0.36);
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.cardWrap {
  &:before {
    content: '';
    background: linear-gradient(90deg, #345CC1 0%, #01C4BD 99.16%);
    margin: 0 15px 50px;
    width: 100%;
    height: 3px;
    @include media(L) {
      margin-top: 50px;
    }
  }
}

.card {
  &:after {
    content: '';
    background: linear-gradient(90deg, #345CC1 0%, #01C4BD 99.16%);
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 0;
    height: 3px;
  }
}

.cardThumb {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  margin-right: 10px;
  width: 100px;
  height: 100px;
  @include media(L) {
    margin-right: 0;
    width: 100%;
    height: 205px;
  }
  &:after {
    content: '';
    background: rgba(65, 68, 195, 0.36);
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.title {
  font-weight: 300;
}

.author {
  display: flex;
}

.authorThumb {
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  margin-right: 10px;
  width: 48px;
  height: 48px;
}

.authorInfo {

}

.authorName {
  font-weight: 300;
  font-size: 15px;
  line-height: 15px;
  color: $purple1;
}

.created {
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  color: rgba(189, 189, 189, 1);
  text-transform: uppercase;
}
.collapse {
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s linear;
}
.open {
  height: auto;
  opacity: 1;
  transition: all 0.3s linear;
}
.arrow {
  top: unset;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  &.arrow_active {
    transform: rotate(180deg);
    top: unset;
    bottom: 20px;
  }
}