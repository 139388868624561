@font-face {
  font-family: "regular";
  src: url('../fonts/Noto_Sans/NotoSans-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: "bold";
  src: url('../fonts/Noto_Sans/NotoSans-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: "light";
  src: url('../fonts/Noto_Sans/NotoSans-Italic.ttf');
  font-display: swap;
}