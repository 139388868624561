$yellow_shine: rgba(249, 201, 66, 0.51);
$yellow: #915EFF;
$purple: #040330;
$purple1: #4144C3;
$blue: #00FFF2;
$grey: #58595B;
$white: #fff;
$violet-gradient: linear-gradient(90deg, #345CC1 0%, #01C4BD 99.16%);
$dark-gradient: linear-gradient(360deg, rgba(88, 89, 91, 0.25) 0%, rgba(88, 89, 91, 0) 74.91%);

$XS: 400px;
$S: 576px;
$M: 768px; // 0- 768 - mobile
$L: 992px; // 768- 992 - tablet
$XL: 1200px; // 992 - infinity - desktop
$XXL: 1450px;
