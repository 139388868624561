@import './../../../assets/styles/variables';

.pagination {
  display: flex;
}

.dot {
  background-color: $purple;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 19px;
  font-weight: 300;
  width: 35px;
  height: 35px;
  color: #fff;
  cursor: pointer;
}

.current {
  background-color: $purple1;
}