@import '../../assets/styles/media-mixin';

@mixin shadow {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 0 20px 1px #130707;
  border-bottom: 1px solid transparent;
}

.frame1 {
  background-color: $purple;
}

.frame1Article {
  font-size: 36px;
  line-height: 42px;
  color: $blue;
  text-align: center;
  @include media(L) {
    text-align: left;
    font-size: 46px;
    line-height: 55px;
  }
}

.frame1Img {
  width: 100%;
}

.frame2 {
  background-color: rgba(4, 3, 48, 0.1);
}

.frame2Sprite {
  flex-shrink: 0;
  margin-right: 10px;
}

.frame2Title {
  @include media(L) {
    text-align: left;
  }

}

.frame3 {
  position: relative;
  overflow-y: hidden;
  &:before {
    @include media(L) {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 246px;
      background-color: rgba(4, 3, 48, 0.1);
    }
  }
  &:after {
    @include shadow;
  }
}

.frame3Top {
  background-color: rgba(4, 3, 48, 0.1);
  @include media(L) {
    background-color: transparent;
  }
}

.frame4 {
}
.frame4Title {
  text-transform: capitalize;
}
.frame4ItemWrap {
  display: flex;
  justify-content: space-around;
}

.frame4Item {
  display: none;
  text-align: center;
  @include media(L) {
    display: block;
  }
  span {
    margin-bottom: 10px;
  }
}

// 5

.frame5 {
  color: $blue;
  background-color: $purple;
  box-shadow: 0 24px 38px 3px #c1bebe;
}

.frame5Title {
  font-family: 'bold', sans-serif;
  font-size: 24px;
  margin-top: 10px;
}

.frame5Nums {
  font-family: 'bold', sans-serif;
  font-size: 36px;
}

.frame5Days {
  font-size: 18px;
}

//6
.tl {

}

.tlCard {
  position: relative;
  overflow: hidden;
  &:after {
    //content: '';
    //position: absolute;
    //left: 0;
    //bottom: 0;
    //width: 100%;
    //box-shadow: 0 0 20px 1px #130707;
    //border-bottom: 1px solid transparent;

    @include shadow
  }
  @include media(L){
    height: 100%;
  }
}

.tlCardTop {
  display: flex;
}

.tlCardThumb {
  border-radius: 10px;
  margin-right: 15px;
  flex-shrink: 0;
  overflow: hidden;
}

.tlCardTopInfo {

}

.tlRates {
  display: flex;
}