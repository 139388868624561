@import '../../assets/styles/media-mixin';

.author {
  display: flex;
  margin-bottom: 20px;
  @include media(L){
    margin-bottom: unset;
  }
}

.authorThumb {
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  margin-right: 10px;
  //flex-shrink: 0;
  width: 48px;
  height: 48px;
}

.authorInfo {

}

.authorName {
  font-weight: 300;
  font-size: 15px;
  line-height: 15px;
  color: $purple1;
}

.created {
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  color: rgba(189, 189, 189, 1);
  text-transform: uppercase;
}