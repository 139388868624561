@import "fonts";
@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: 'robotoRegular', sans-serif;
  color: #343a40;
}

#root {
  margin-top: 77px;
}

@media (max-width: 992px) {
  #root {
    margin-top: 67px;
  }
}

.modal-dialog { max-width: 363px !important; }
.modal-content { background-color: transparent !important }

.button {
  display: inline-block;
  background-color: #4144C3;
  border-radius: 25px;
  font-size: 28px;
  line-height: 48px;
  color: #fff;
  padding: 2px 25px;
  border: none;
  &:hover, &:focus {
    text-decoration: none;
    color: #fff;
  }
}

a:hover {
  text-decoration: none;
}

.info__arrow{
  width: 14px;
  height: 8px;
  position: absolute;
  right: 10px;
  top: 8px;
  transition: .3s ease;
  &.activeTab {
    transform: rotate(180deg);
  }
}

