@import '../../assets/styles/media-mixin';

.sprite {
  display: block;
  background-image: url('./../../assets/img/frame.png');
  background-repeat: no-repeat;
  &-logo {
    width: 110.37px;
    height: 47px;
    background-position: 0 -218px;
  }
  &-title {
    width: 137px;
    height: 34px;
    background-position: 0 -184px;
  }

  &-frame2-1, &-frame2-2, &-frame2-3 {
    width: 82px;
    height: 150px;
    background-position: 0 0;
  }
  &-frame2-2 {
    background-position: -82px 0;
  }
  &-frame2-3 {
    background-position: -164px 0;
  }

  &-frame4-1, &-frame4-2, &-frame4-3, &-frame4-4, &-frame4-5, &-frame4-6, &-frame4-7 {
    width: 140px;
    height: 140px;
    background-position: -246px 0;
  }

  &-frame4-2 {
    background-position: -386px 0;
  }

  &-frame4-3 {
    background-position: -526px 0;
  }

  &-frame4-4 {
    background-position: -666px 0;
  }

  &-frame4-5 {
    background-position: -806px 0;
  }

  &-frame4-6 {
    background-position: -946px 0;
  }

  &-frame4-7 {
    background-position: -1086px 0;
  }

  &-frame5-1 {
    width: 54.14px;
    height: 26px;
    background-position: -137px -158px;
  }

  &-rate {
    width: 54.14px;
    height: 26px;
    background-position: -137px -184px;
  }
  &-frame-media{
    width: 31px;
    height: 25px;
    background-position: -191px -159px;
  }
  &-frame-case-1 {
    width: 310px;
    height: 256px;
    background-position: -20px -433px;
  }
  &-frame-case-2 {
    width: 310px;
    height: 256px;
    background-position: -330px -433px;
  }
  &-frame-case-3 {
    width: 310px;
    height: 256px;
    background-position: -650px -433px;
  }
  &-frame-case-4 {
    width: 310px;
    height: 256px;
    background-position: -970px -433px;
  }
  &-frame-case-5 {
    width: 19px;
    height: 20px;
    background-position: -228px -148px;
  }
  &-frame-arrow {
    width: 14px;
    height: 8px;
    background-position: -49px  -423px;
  }
  &-tm-ico-1 {
    background-position: -89px -335px;
    width: 96px;
    height: 96px;
  }
  &-tm-ico-2 {
    background-position: -185px -336px;
    width: 96px;
    height: 96px;
  }
  &-tm-ico-3 {
    background-position: -281px -335px;
    width: 96px;
    height: 96px;
  }

  &-foot {
    background-position: 0 -184px;
    width: 137px;
    height: 34px;
    transition: .1s ease-out;
    @include media(L) {
      background-position: 0 -150px;
    }
    //&:hover {
    //  background-position: 0 -184px;
    //}
  }

  &-star1, &-star0 {
    width: 14px;
    height: 14px;
  }

  &-star1 {
    background-position: -20px -418px;
  }

  &-star0 {
    background-position: -34px -418px;
  }

  &-reg {
    background-position: 0 -414px;
    width: 20px;
    height: 18px;
  }
}