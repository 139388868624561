@import '../../../assets/styles/variables';

$width: 250px;

.mob-menu {
  background: $purple;
  position: absolute;
  top: 0;
  left: 0;
  width: $width;
  transition: .3s ease-out;
  transform: translateX(-$width);
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $purple;
    height: 67px;
    width: 67px;
    transform: translateX($width);
  }

  &__burger {
    background-color: #fff;
    position: relative;
    width: 40px;
    height: 5px;
    border-radius: 5px;
    transition: .3s ease-out;
    &:before, &:after {
      content: '';
      background-color: #fff;
      position: absolute;
      left: 0;
      width: 40px;
      height: 5px;
      border-radius: 5px;
    }
    &:before {
      top: -10px;
    }
    &:after {
      top: 10px;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    max-width: 120px;
    margin-left: auto;
    margin-right: auto;
    a {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 36px;
      color: #fff;
      text-transform: capitalize;
    }
    a:last-child {
      margin-bottom: 0;
    }
  }
}

.open {
  filter: drop-shadow(2px 4px 6px black);
  transform: translateX(0);
  .mob-menu__burger {
    transform: rotate(45deg);
    &:before {
      top: 0;
    }
    &:after {
      top: 0;
      transform: rotate(-90deg);
    }
  }
}