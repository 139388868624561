@import '../../assets/styles/media-mixin';

.field {
  margin-bottom: 20px;
  input, textarea {
    font-weight: 300;
    border: none;
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 22px;
    line-height: 50px;
    color: #58595B;
    width: 100%;
    &:hover, &:active, &:visited, &:focus {
      outline: none;
    }
  }
  textarea {
    line-height: 30px;
  }

  button {
    font-weight: 300;
    text-transform: uppercase;
  }
}

.checkField {

}

.submit {
  display: block;
  font-weight: 300;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  @include media(L) {
    margin-left: unset;
  }
}

.error {
  font-weight: 300;
  font-size: 14px;
  color: #fff;
  text-transform: capitalize;
}