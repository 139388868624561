@import './../../../assets/styles/variables';

.wrap {
  display: flex;
}

.checkboxes {
  position: relative;
  margin-right: 10px;
  width: 26px;
  height: 26px;
}

.hidden {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}

.visible {
  background-color: #fff;
  width: 24px;
  height: 24px;
  border: 3px solid $purple1;
  border-radius: 5px;
  cursor: pointer;
}

.checked {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $purple1;
  }
}

.text {
  color: $blue;
  span {
    color: $purple1;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: $purple1;;
      text-decoration: none;
    }
  }
}