.title {
  font-weight: bold;
  font-size: 55px;
  line-height: 63px;
}

.thumb {
  width: 100%;
}

.maxWidth {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
