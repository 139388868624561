.wrap {
  text-align: center;
  padding-top: 15vh;
  padding-bottom: 15vh;
}

.wrapSmall {
  max-width: 55px;
  img {width: 100%}
}

.gifIco {

}