@import '../../assets/styles/media-mixin';

.wrap {
  background-color: $purple;
}

.wrapTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  font-size: 32px;
  line-height: 24px;
  color: $blue;
  text-transform: uppercase;
  @include media(L) {
    flex-direction: row;
    align-items: flex-end;
  }
  span {
    margin-top: 10px;
    @include media(L) {
      margin-top: 0;
      margin-left: 20px;
    }
  }
}
.contact_text {
  color: $white;
}

.text {
  color: #fff;
}

.copy {
  color: $purple1
}
