@import '../../assets/styles/media-mixin';

$var: 30;

.wrap {
  background-color: #fff;
  max-width: 363px;
  border-radius: 11px;
  overflow: hidden;
  padding-left: $var + px;
  padding-right: $var + px;
  padding-bottom: $var + px;
}

.header {
  background-image: url("../../assets/img/modal.jpg");
  background-repeat: no-repeat;
  background-position-x: center;
  height: 76px;
  text-transform: uppercase;
  margin-left: - $var + px;
  margin-right: - $var + px;
}

.button {
  width: 100%;
  border-radius: 10px;
  text-transform: capitalize;
}